/*
  	Flaticon icon font: Flaticon
  	Creation date: 13/10/2017 10:29
  	*/

@font-face {
  font-family: 'Flaticon';
  src: url('./Flaticon.eot');
  src:
    url('./Flaticon.eot?#iefix') format('embedded-opentype'),
    url('./Flaticon.woff') format('woff'),
    url('./Flaticon.ttf') format('truetype'),
    url('./Flaticon.svg#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'Flaticon';
    src: url('./Flaticon.svg#Flaticon') format('svg');
  }
}

[class^='flaticon-']:before,
[class*=' flaticon-']:before,
[class^='flaticon-']:after,
[class*=' flaticon-']:after {
  font-family: Flaticon;
  font-size: 20px;
  font-style: normal;
  margin-left: 20px;
}

.flaticon-shapes-1:before {
  content: '\f100';
}
.flaticon-signs-1:before {
  content: '\f101';
}
.flaticon-info:before {
  content: '\f102';
}
.flaticon-questions-circular-button:before {
  content: '\f103';
}
.flaticon-question:before {
  content: '\f104';
}
.flaticon-garbage-1:before {
  content: '\f105';
}
.flaticon-trash:before {
  content: '\f106';
}
.flaticon-garbage:before {
  content: '\f107';
}
.flaticon-mail-5:before {
  content: '\f108';
}
.flaticon-paper-plane:before {
  content: '\f109';
}
.flaticon-send:before {
  content: '\f10a';
}
.flaticon-mail-4:before {
  content: '\f10b';
}
.flaticon-mail-3:before {
  content: '\f10c';
}
.flaticon-mail-2:before {
  content: '\f10d';
}
.flaticon-mail-1:before {
  content: '\f10e';
}
.flaticon-mail:before {
  content: '\f10f';
}
.flaticon-database-information:before {
  content: '\f110';
}
.flaticon-folder-4:before {
  content: '\f111';
}
.flaticon-check-1:before {
  content: '\f112';
}
.flaticon-database-8:before {
  content: '\f113';
}
.flaticon-database-7:before {
  content: '\f114';
}
.flaticon-database-6:before {
  content: '\f115';
}
.flaticon-database-5:before {
  content: '\f116';
}
.flaticon-alert:before {
  content: '\f117';
}
.flaticon-database-4:before {
  content: '\f118';
}
.flaticon-database-3:before {
  content: '\f119';
}
.flaticon-database-2:before {
  content: '\f11a';
}
.flaticon-add-1:before {
  content: '\f11b';
}
.flaticon-database-1:before {
  content: '\f11c';
}
.flaticon-technology-2:before {
  content: '\f11d';
}
.flaticon-floppy-disk-1:before {
  content: '\f11e';
}
.flaticon-save-disk:before {
  content: '\f11f';
}
.flaticon-floppy-disk:before {
  content: '\f120';
}
.flaticon-save-1:before {
  content: '\f121';
}
.flaticon-export-1:before {
  content: '\f122';
}
.flaticon-export-document:before {
  content: '\f123';
}
.flaticon-save-file-option:before {
  content: '\f124';
}
.flaticon-save:before {
  content: '\f125';
}
.flaticon-bar-chart-and-polyline:before {
  content: '\f126';
}
.flaticon-comparison-2:before {
  content: '\f127';
}
.flaticon-comparison-1:before {
  content: '\f128';
}
.flaticon-comparison:before {
  content: '\f129';
}
.flaticon-cloud-computing:before {
  content: '\f12a';
}
.flaticon-import:before {
  content: '\f12b';
}
.flaticon-export:before {
  content: '\f12c';
}
.flaticon-pie-chart-1:before {
  content: '\f12d';
}
.flaticon-graphic:before {
  content: '\f12e';
}
.flaticon-bar-chart:before {
  content: '\f12f';
}
.flaticon-calculator:before {
  content: '\f130';
}
.flaticon-folder-3:before {
  content: '\f131';
}
.flaticon-interface-3:before {
  content: '\f132';
}
.flaticon-web-2:before {
  content: '\f133';
}
.flaticon-web-1:before {
  content: '\f134';
}
.flaticon-folder-2:before {
  content: '\f135';
}
.flaticon-web:before {
  content: '\f136';
}
.flaticon-folder-1:before {
  content: '\f137';
}
.flaticon-square-1:before {
  content: '\f138';
}
.flaticon-bars-1:before {
  content: '\f139';
}
.flaticon-interface-2:before {
  content: '\f13a';
}
.flaticon-location:before {
  content: '\f13b';
}
.flaticon-circle:before {
  content: '\f13c';
}
.flaticon-technology-1:before {
  content: '\f13d';
}
.flaticon-line:before {
  content: '\f13e';
}
.flaticon-check:before {
  content: '\f13f';
}
.flaticon-arrows:before {
  content: '\f140';
}
.flaticon-signs:before {
  content: '\f141';
}
.flaticon-folder:before {
  content: '\f142';
}
.flaticon-technology:before {
  content: '\f143';
}
.flaticon-layers-2:before {
  content: '\f144';
}
.flaticon-shapes:before {
  content: '\f145';
}
.flaticon-interface-1:before {
  content: '\f146';
}
.flaticon-app:before {
  content: '\f147';
}
.flaticon-interface:before {
  content: '\f148';
}
.flaticon-mark:before {
  content: '\f149';
}
.flaticon-bars:before {
  content: '\f14a';
}
.flaticon-multimedia:before {
  content: '\f14b';
}
.flaticon-cross:before {
  content: '\f14c';
}
.flaticon-layers-1:before {
  content: '\f14d';
}
.flaticon-plus:before {
  content: '\f14e';
}
.flaticon-success:before {
  content: '\f14f';
}
.flaticon-placeholder-1:before {
  content: '\f150';
}
.flaticon-settings:before {
  content: '\f151';
}
.flaticon-add:before {
  content: '\f152';
}
.flaticon-placeholder:before {
  content: '\f153';
}
.flaticon-star-1:before {
  content: '\f154';
}
.flaticon-star:before {
  content: '\f155';
}
.flaticon-route:before {
  content: '\f156';
}
.flaticon-database:before {
  content: '\f157';
}
.flaticon-square:before {
  content: '\f158';
}
.flaticon-layer:before {
  content: '\f159';
}
.flaticon-vector-1:before {
  content: '\f15a';
}
.flaticon-layers:before {
  content: '\f15b';
}
.flaticon-selection-3:before {
  content: '\f15c';
}
.flaticon-selection-2:before {
  content: '\f15d';
}
.flaticon-vector:before {
  content: '\f15e';
}
.flaticon-selection-1:before {
  content: '\f15f';
}
.flaticon-selection:before {
  content: '\f160';
}
